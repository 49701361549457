import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { MFieldConnector, MText, Yup } from '@mprise/react-ui'
import { Box } from '@mui/system'
import { Field, useFormikContext } from 'formik'
import { MutationResult, useLazyQuery, useMutation } from '@apollo/client'
import { useHistory } from '../../shared/use-history'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe } from '../../shared/typescript'
import { withFormikCompareFix } from '../../shared/formik'
import { useLocalState } from '../../shared/react-local-state'
import { DeleteRecordDialog } from '../../shared/dialog/delete-record-dialog'
import {
  DELETE_AUTO_PICK_RULE,
  GET_AUTO_PICK_RULE,
  GET_AUTO_PICK_RULES,
  UPSERT_AUTO_PICK_RULE,
} from '../../gql/auto-pick-rules'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { FieldGenus } from '../../shared/form/field-genus'
import { FieldItemSet } from '../../shared/form/field-item-set'
import { FieldVariantSet } from '../../shared/form/field-variant-set'
import { FieldPosition } from '../../shared/form/field-position'
import { FieldDropdown } from '../../shared/form/field-dropdown'
import { MutationErrorMessage } from '../../shared/react-apollo'
import { FieldResource } from '../../shared/form/field-resource'

export interface UpsertRuleFormValues {
  id?: Maybe<number>
  genus: { id: number; name: string; code: string }
  items: Array<{ id: number; name: string }>
  variants: Array<{ id: number; code: string; item: { id: number } }>
  allItems: boolean
  allVariants: boolean
  status: string
  defaultResource: { id: number; name: string }
  defaultPosition: { id: number; code: string }
}

export const AutoPickRuleDialog = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = AutoPickRuleDialog.useSchema()
  const companyId = useCurrentCompanyId()

  const { ruleId } = useParams() as { ruleId: string }

  const [getAutoPickRule, { data: ruleData, loading: ruleLoading }] = useLazyQuery(GET_AUTO_PICK_RULE)

  const [upsertAutoPickRule, { error: upsertError }] = useMutation(UPSERT_AUTO_PICK_RULE, {
    refetchQueries: [GET_AUTO_PICK_RULES],
  })

  useEffect(() => {
    if (ruleId) {
      getAutoPickRule({
        variables: {
          companyId: +companyId,
          id: +ruleId,
        },
      })
    }
  }, [companyId, getAutoPickRule, ruleId])

  const [initialValues] = useLocalState<UpsertRuleFormValues>(() => {
    const rule = ruleData?.autoPickRule
    return withFormikCompareFix({
      id: rule?.id ?? null,
      genus: rule?.genus ?? null,
      items: rule?.items ?? [],
      variants: rule?.variants ?? [],
      allItems: rule?.allItems ?? false,
      allVariants: rule?.allVariants ?? false,
      status: rule?.status ?? null,
      defaultResource: rule?.defaultResource ?? null,
      defaultPosition: rule?.defaultPosition ?? null,
    })
  }, [ruleData])

  const handleSave = async (formValues: UpsertRuleFormValues) => {
    const response = await upsertAutoPickRule({
      variables: {
        companyId: +companyId,
        input: {
          id: formValues.id,
          genusId: +formValues.genus.id,
          itemIds: formValues.items.map(x => +x.id),
          variantIds: formValues.variants.map(x => +x.id),
          allItems: formValues.allItems,
          allVariants: formValues.allVariants,
          status: formValues.status,
          defaultResourceId: +formValues.defaultResource.id,
          defaultPositionId: formValues.defaultPosition.id,
        },
      },
    })

    const upsertedRuleId = response.data?.upsertAutoPickRule?.id
    if (upsertedRuleId) {
      handleClose()
    }
  }

  const handleClose = () => {
    h.push('/auto-pick', { replace: true })
  }

  const [deleting, setDeleting] = useState<Maybe<{ id: number; name: string }>>(null)
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const rule = ruleData?.autoPickRule
    if (rule) {
      setDeleting(rule)
    }
  }
  const deleteDialogClose = () => setDeleting(null)

  return (
    <>
      {deleting && (
        <DeleteRecordDialog
          record={deleting}
          recordType={t('Auto Pick Rule')}
          onClose={deleteDialogClose}
          editPopupClose={handleClose}
          deleteQuery={DELETE_AUTO_PICK_RULE}
          variables={{ companyId: +companyId, id: deleting.id }}
          refetchQueries={[GET_AUTO_PICK_RULES]}
        />
      )}
      <PatternDialog<UpsertRuleFormValues>
        title={ruleId ? t('Edit Auto Pick Rule') : t('New Auto Pick Rule')}
        submit={ruleId ? t('Save') : t('Create')}
        initialValues={initialValues}
        onClose={handleClose}
        onSave={handleSave}
        onDelete={ruleId ? handleDelete : undefined}
        schema={schema}
        open={true}
        query={[{ loading: ruleId ? ruleLoading : false }]}
      >
        <MutationErrorMessage mutation={{ error: upsertError } as MutationResult} />
        <AutoPickRuleDialogForm />
      </PatternDialog>
    </>
  )
}

const AutoPickRuleDialogForm = () => {
  const { t } = useTranslation()

  const fc = useFormikContext<UpsertRuleFormValues>()

  useEffect(() => {
    if (fc.dirty) {
      fc.setFieldValue('items', [])
      fc.setFieldValue('variants', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Should not include 'fc', that causes an infinite render loop.
  }, [fc.values.genus])

  useEffect(() => {
    if (fc.dirty) {
      if (fc.values.allItems) {
        return
      } else if (fc.values.items?.length > 0) {
        const newSelectedItemIds = fc.values.items.map(item => item.id)
        const filteredVariants = fc.values.variants?.filter(variant => newSelectedItemIds.includes(variant.item.id))
        fc.setFieldValue('variants', filteredVariants ?? [])
      } else {
        fc.setFieldValue('variants', [])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Should not include 'fc', that causes an infinite render loop.
  }, [fc.values.items, fc.values.allItems])

  return (
    <Box minWidth={400} padding={2}>
      <MText style={{ paddingLeft: '0.5rem' }} block textVariant='small bold'>
        {t('Triggers')}
      </MText>
      <Field component={MFieldConnector} name='genus' label={t('Genus')}>
        <FieldGenus />
      </Field>
      <Field component={MFieldConnector} name='items' label={t('Items')}>
        <FieldItemSet
          filterByGenus={fc.values.genus}
          disabled={!fc.values.genus?.id}
          allSelectedInForm={fc.values.allItems}
          setAllSelectedInForm={newValue => fc.setFieldValue('allItems', newValue)}
        />
      </Field>
      <Field component={MFieldConnector} name='variants' label={t('Variants')}>
        <FieldVariantSet
          filterBy={
            fc.values.allItems ? { genus: fc.values.genus?.code } : { itemIdIn: fc.values.items.map(x => +x.id) }
          }
          disabled={!(fc.values.items?.length > 0 || fc.values.allItems)}
          allSelectedInForm={fc.values.allVariants}
          setAllSelectedInForm={newValue => fc.setFieldValue('allVariants', newValue)}
        />
      </Field>
      <Field component={MFieldConnector} name='status' label={t('Status Category')}>
        <FieldDropdown values={['CREATED', 'ACTIVE', 'INACTIVE']} />
      </Field>
      <br />
      <br />
      <MText style={{ paddingLeft: '0.5rem', marginBottom: '0.5rem' }} block textVariant='small bold'>
        {t('Default values')}
      </MText>
      <Field component={MFieldConnector} name='defaultResource' label={t('Resource')}>
        <FieldResource title={t('Select Resource')} />
      </Field>
      <Field component={MFieldConnector} name='defaultPosition' label={t('Position')}>
        <FieldPosition />
      </Field>
    </Box>
  )
}

AutoPickRuleDialog.useSchema = () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    genus: Yup.object().label(t('Genus')).required(),
    items: Yup.array()
      .label(t('Items'))
      .test('is-required', 'Items is a required field', (value, context: { parent: UpsertRuleFormValues }) =>
        Boolean(value?.length || context.parent.allItems),
      ),
    variants: Yup.array()
      .label(t('Variants'))
      .test('is-required', 'Variants is a required field', (value, context: { parent: UpsertRuleFormValues }) =>
        Boolean(value?.length || context.parent.allVariants),
      ),
    status: Yup.string().label(t('Status')).required(),
    defaultResource: Yup.object().label(t('Resource')).required(),
    defaultPosition: Yup.object().label(t('Position')).required(),
  })
}
