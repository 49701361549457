import styled from '@emotion/styled'
import { Checkbox, ListItemText } from '@mui/material'
import { MDivider, MFlexBlock, MFlexItem, MJoinChildren, useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectiveSearch } from '../react-selective-search'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { WorkItemType } from '../../lib/enums'

export const SelectWorkItemTypesDialog = ({
  loading,
  open,
  title,
  onClose,
  onSave,
  initialValue,
}: {
  loading: boolean
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: WorkItemType[]) => void
  initialValue?: WorkItemType[]
}) => {
  const [search, setSearch] = useState<string>('')
  const types = Object.values(WorkItemType)

  const typesFiltered = useSelectiveSearch(search, types, type => type)

  return (
    <SearchAndSelectDialog
      loading={loading}
      initialValue={initialValue}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={selected => onSave(selected as WorkItemType[])}
      onChange={(text: string) => {
        setSearch(text)
      }}
    >
      <MDivider />
      <SelectWorkItemTypesDialogList items={typesFiltered} />
    </SearchAndSelectDialog>
  )
}

const SelectWorkItemTypesDialogList = ({ items }: { items: string[] }) => {
  return (
    <MJoinChildren divider={MDivider}>
      {items.map(t => {
        return <SelectWorkItemTypesDialogItem key={t} item={t} />
      })}
    </MJoinChildren>
  )
}

const SelectWorkItemTypesDialogItem = ({ item }: { item: string }) => {
  const field = useMField()
  const selectedItems = field.value as string[]
  const selected = selectedItems.includes(item)

  const { t } = useTranslation()

  const handleClick = () => {
    if (selected) {
      const newSelection = selectedItems.slice().filter(x => x !== item)
      field.onChange?.(newSelection)
    } else {
      field.onChange?.([...selectedItems, item])
    }
  }

  return (
    <MFlexBlockClickable gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <Checkbox checked={selected} />
      <MFlexItem grow={1}>
        <ListItemText primary={t(`WorkItemType.${item}`)} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
  color: inherit;
`
