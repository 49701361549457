import { useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { GET_SKILLS } from '../../gql/skills'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { FieldSelect } from './field-select'

export type SkillValue = {
  id: number
  name: string
  code: string
  description?: string
}

export const FieldSkillSet = ({ title }: { title: string }) => {
  const f = useMField()
  const selected = f.value as SkillValue[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  return (
    <>
      <MultiSelectDialog<SkillValue>
        displayProperty='name'
        open={open}
        onClose={handleClose}
        onSave={skills => {
          f.onChange?.(skills)
          setOpen(false)
        }}
        title={title}
        initialValue={selected}
        getRecordsQuery={GET_SKILLS}
        queryResultAccessor={data => data?.skills}
      />
      <FieldSelect<SkillValue>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
