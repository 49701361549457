import { useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { GET_TEAMS } from '../../gql/teams'
import { FieldSelect } from './field-select'

export type SimpleTeam = {
  id: number
  name: string
}

export const FieldTeamSet = ({ title }: { title: string }) => {
  const f = useMField()
  const selected = f.value as SimpleTeam[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (teams: SimpleTeam[]) => {
    f.onChange?.(teams)
    handleClose()
  }

  return (
    <>
      {open && (
        <MultiSelectDialog<SimpleTeam>
          displayProperty='name'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={title}
          initialValue={selected}
          getRecordsQuery={GET_TEAMS}
          queryResultAccessor={data => data?.teams}
        />
      )}
      <FieldSelect<SimpleTeam>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
