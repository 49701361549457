import { gql } from '@apollo/client'

export const GET_TEMPLATE = gql`
  query Template($filter: WorkitemTemplateFilter) {
    workItemTemplate(filter: $filter) {
      id
      type
      name
      tasks {
        order
        name
        types
        taskOptions
        settingFinishOnFulfilled
        settingItemOutputWarehouseStatus {
          id
          name
        }
        settingsResourceReport
        settingItemConsumptionWarehouseStatus {
          id
          name
        }
        settingPackInputNextWarehouseStatus {
          id
          name
        }
        settingPackOutputNextWarehouseStatus {
          id
          name
        }
        settingLoadOutputNextCarrierStatus
        settingLoadInputNextWarehouseStatus {
          id
          name
        }
        settingStatusChangeWarehouseStatus {
          id
          name
        }
      }
      activity {
        id
        code
        name
      }
      autoClose
      defaultOwner {
        id
        code
        name
      }
      defaultPriority
      defaultSkills {
        id
        name
        code
      }
    }
  }
`

export const GET_ALL_TEMPLATES = gql`
  query Templates($filter: WorkitemTemplateFilter, $limit: Int, $offset: Int) {
    workItemTemplates(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        type
        name
        appName
        activity {
          id
          name
          code
        }
      }
      total
    }
  }
`

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: CreateWorkitemTemplateInput) {
    createWorkItemTemplate(input: $input) {
      id
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($id: Int!, $input: UpdateWorkitemTemplateInput) {
    updateWorkItemTemplate(id: $id, input: $input) {
      id
    }
  }
`

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($id: Int!) {
    deleteWorkItemTemplate(id: $id)
  }
`
