import { gql } from '@apollo/client'

export const GET_TRID_STATUS = gql`
  query TrackingStatus($statusId: Int) {
    warehouseTrackingStatus(statusId: $statusId) {
      id
      name
      activeUntil
      agriwareStatus
      isDefault
      lastModified
      cosmosKey
    }
  }
`

export const GET_TRID_STATUSES = gql`
  query TrackingStatuses($companyId: Int, $statuses: [TrackingStatus]) {
    customStatuses(companyId: $companyId, statuses: $statuses) {
      id
      name
      activeUntil
      agriwareStatus
      isDefault
      lastModified
      cosmosKey
      removed
    }
  }
`

export const CREATE_TRID_STATUS = gql`
  mutation CreateTrackingStatuses(
    $name: String!
    $agriwareStatus: TrackingStatus!
    $companyId: Int
    $activeUntil: LocalDate
    $isDefault: Boolean
  ) {
    createWarehouseTrackingStatus(
      name: $name
      agriwareStatus: $agriwareStatus
      companyId: $companyId
      activeUntil: $activeUntil
      isDefault: $isDefault
    ) {
      id
    }
  }
`

export const UPDATE_TRID_STATUS = gql`
  mutation UpdateTrackingStatuses(
    $id: Int
    $name: String
    $agriwareStatus: TrackingStatus
    $isDefault: Boolean!
    $activeUntil: LocalDate
  ) {
    warehouseTrackingStatus(id: $id) {
      update(name: $name, agriwareStatus: $agriwareStatus, isDefault: $isDefault, activeUntil: $activeUntil) {
        id
      }
    }
  }
`

export const DELETE_TRID_STATUS = gql`
  mutation RemoveTrackingStatuses($id: Int) {
    warehouseTrackingStatus(id: $id) {
      remove
    }
  }
`
