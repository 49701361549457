import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContentText, TextField } from '@mui/material'
import { MPortalDialog } from '@mprise/react-ui'
import { AlertType } from '../../shared/alert-dialog'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TEAM, DELETE_TEAM, GET_TEAMS } from '../../gql/teams'
import { MutationErrorMessage } from '../../shared/react-apollo'

export const DeleteTeamDialog = ({
  handleAlert,
  open,
  teamId,
  onClose,
}: {
  handleAlert: (alertType: AlertType) => void
  open: boolean
  teamId: string
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const [deleteTeam, deleteTeamState] = useMutation(DELETE_TEAM, {
    refetchQueries: [GET_TEAMS, 'teams'],
  })

  const [getTeam, { data: team }] = useLazyQuery(GET_TEAM)

  useEffect(() => {
    if (teamId) {
      getTeam({
        variables: {
          filter: {
            id: +teamId,
          },
        },
      })
    }
  }, [getTeam, teamId])

  useEffect(() => {
    if (deleteTeamState.data) {
      handleAlert('delete')
      onClose()
    }
  }, [deleteTeamState.data, handleAlert, onClose])

  const onCommit: React.FormEventHandler = async e => {
    e.preventDefault()
    deleteTeam({ variables: { id: +teamId } })
  }

  return (
    <MPortalDialog
      open={open}
      title={t('DialogDeleteTeamTitle')}
      submit={t('DialogDeleteTeamTitle')}
      onSubmit={onCommit}
      onClose={onClose}
    >
      <MutationErrorMessage mutation={deleteTeamState} />
      <DialogContentText>{t('TeamDeleteDialogHint', { name: team?.team.name })}</DialogContentText>
      <TextField
        fullWidth
        variant='outlined'
        label={t('Name')}
        value={team ? team.team.name : ''}
        InputProps={{ readOnly: true }}
      />
    </MPortalDialog>
  )
}
