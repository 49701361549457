import { useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { GET_RESOURCES } from '../../gql/resource'
import { SingleSelectDialog } from '../dialog/single-select-dialog'
import { FieldSelect } from './field-select'
import { ResourceValue } from './field-resource-set'

export const FieldResource = ({ title }: { title: string }) => {
  const f = useMField()
  const selected = f.value as ResourceValue
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (resource: ResourceValue) => {
    f.onChange?.(resource)
    handleClose()
  }

  return (
    <>
      {open && (
        <SingleSelectDialog<ResourceValue>
          displayProperty='name'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={title}
          initialValue={selected}
          getRecordsQuery={GET_RESOURCES}
          queryResultAccessor={data => data?.resources?.page}
        />
      )}
      <FieldSelect<ResourceValue>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
