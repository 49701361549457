import { MColor, MFlexBlock, MFlexItem, MText, useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { SelectWorkItemTypesDialog } from '../dialog/select-workitem-types-dialog'
import { WorkItemType } from '../../lib/enums'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { mdiMagnify } from '@mdi/js'
import CloseIcon from '@mui/icons-material/Close'
import Icon from '@mdi/react'

import { IconButton } from '@mui/material'

export const FieldRPGWorkItemType = ({ title }: { title: string }) => {
  const f = useMField()
  const { t } = useTranslation()

  const selected = f.value as WorkItemType[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const selectedText = selected
    .map(x => t(`WorkItemType.${x}`))
    .sort()
    .join(', ')

  return (
    <>
      <SelectWorkItemTypesDialog
        initialValue={selected}
        open={open}
        onClose={handleClose}
        onSave={(items: WorkItemType[]) => {
          f.onChange?.(items)
          setOpen(false)
        }}
        title={title}
        loading={false}
      />
      <FieldWorkItemType
        selected={f.value}
        onExplore={handleOpen}
        renderLabel={() => <MText block>{selectedText}</MText>}
        onClear={handleClear}
      />
    </>
  )
}

const FieldWorkItemType = <T extends unknown>({
  selected,
  renderLabel,
  onClear,
  onExplore,
}: {
  selected: T | null
  renderLabel: (item: T) => React.ReactNode
  onClear?: () => void
  onExplore?: () => void
}) => {
  const isClear = selected === undefined || selected === null || (Array.isArray(selected) && selected.length === 0)
  const handleClear = () => onClear?.()
  const handleExplore = () => onExplore?.()
  return (
    <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
      <MFlexItemClickable grow={1} onClick={handleExplore}>
        {isClear ? (
          <MText block textVariant='content bold'>
            -
          </MText>
        ) : (
          renderLabel(selected!)
        )}
      </MFlexItemClickable>
      {onClear && !isClear && (
        <IconButton onClick={handleClear}>
          <CloseIcon />
        </IconButton>
      )}
      {onExplore && (
        <IconButton onClick={handleExplore}>
          <Icon path={mdiMagnify} size={1} />
        </IconButton>
      )}
    </MFlexBlock>
  )
}

const MFlexItemClickable = styled(MFlexItem)`
  cursor: pointer;
`
