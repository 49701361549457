import { Collapse } from '@mui/material'
import { MBlock, MFieldInput, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { Field, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useHistory } from '../../shared/use-history'
import * as yup from 'yup'
import { IconWarning } from '../../icons'
import { FieldPriority } from '../../shared/form/field-priority'
import { FieldSkillSet } from '../../shared/form/field-skill-set'
import { AutocompleteField, CheckboxField } from '../../shared/material-ui-formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { PatternDialog } from '../../shared/pattern-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { defined, FallbackValues, Maybe, withFallbackValuesMaybe } from '../../shared/typescript'
import { yupEnum, yupObject, yupObjectArray } from '../../shared/yup-common-types'
import { AvailableResultTypes } from './restrictions'
import { TaskListField } from './task-list-field'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { FieldResource } from '../../shared/form/field-resource'
import { FieldActivity } from '../../shared/form/field-activity'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TEMPLATE, GET_ALL_TEMPLATES, CREATE_TEMPLATE, UPDATE_TEMPLATE } from '../../gql/templates'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import {
  WorkItemType,
  WorkPriority,
  WorkResultType,
  CarrierStatus,
  WorkItemTemplateTaskOption,
  ResourceReportOption,
  AppName,
} from '../../lib/enums'
import { useRoleAvailable } from '../../auth'
import { DeleteTemplateDialog } from './dialog-delete-template'

export interface WorkItemTemplateForm {
  id: Maybe<number>
  companyId: number
  type: Maybe<{ id: string; name: string }>
  name: Maybe<string>
  activity: Maybe<{ id: number; name: string; code: Maybe<string> }>
  tasks: Maybe<Array<WorkItemTemplateTaskForm>>
  autoClose: Maybe<boolean>
  defaultPriority: WorkPriority
  defaultOwner: Maybe<{ id: number; name: string; code: Maybe<string> }>
  defaultSkills: Array<{ id: number; name: string }>
}

export interface WorkItemTemplateTaskForm {
  order: number
  name: string
  types: Array<WorkResultType>
  taskOptions: Array<WorkItemTemplateTaskOption>
  settingFinishOnFulfilled: Maybe<boolean>
  settingItemOutputWarehouseStatus: Maybe<{ id: number; name: string }>
  settingItemConsumptionWarehouseStatus: Maybe<{ id: number; name: string }>
  settingPackInputNextWarehouseStatus: Maybe<{ id: number; name: string }>
  settingPackOutputNextWarehouseStatus: Maybe<{ id: number; name: string }>
  settingLoadInputNextWarehouseStatus: Maybe<{ id: number; name: string }>
  settingLoadOutputNextCarrierStatus: Maybe<CarrierStatus>
  settingsResourceReport: Maybe<ResourceReportOption>
}

export const TemplateDialog = () => {
  const params = useParams()
  const h = useHistory()
  const { t } = useTranslation()

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)

  const action = params.action ?? null
  const templateId = params.templateId ?? null
  const companyId = useCurrentCompanyId()

  const [getTemplate, { data: template }] = useLazyQuery(GET_TEMPLATE, {
    onError: console.error,
  })
  const [getTemplates, { data: templates }] = useLazyQuery(GET_ALL_TEMPLATES, {
    onError: console.error,
  })

  const [createTemplate, { data: createData }] = useMutation(CREATE_TEMPLATE, {
    refetchQueries: [GET_ALL_TEMPLATES, 'workItemTemplates'],
    onError: console.error,
  })
  const [updateTemplate, { data: updateData }] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [GET_ALL_TEMPLATES, 'workItemTemplates', GET_TEMPLATE],
    onError: console.error,
  })

  useEffect(() => {
    if (templateId) {
      getTemplate({
        variables: {
          filter: {
            id: +templateId,
          },
        },
      })
    }

    getTemplates({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
        },
      },
    })
  }, [])

  useEffect(() => {
    if (createData) {
      handleAlert('create')
      h.push(`/work-item-templates`)
    }
  }, [createData])

  useEffect(() => {
    if (updateData) {
      handleAlert('edit')
      h.push(`/work-item-templates`)
    }
  }, [updateData])

  const toWorkItemType = (type: WorkItemType) => ({ id: type, name: t(`WorkItemType.${type}`) })

  const [initialValues] = useLocalState<WorkItemTemplateForm>(
    () =>
      template
        ? {
            companyId: +companyId,
            id: template.workItemTemplate.id ?? ``,
            type: template.workItemTemplate.type ? toWorkItemType(template.workItemTemplate.type) : null,
            name: template.workItemTemplate.name ?? ``,
            tasks: (template.workItemTemplate.tasks ?? []).map((x: any) => ({
              name: x.name,
              order: x.order,
              types: x.types,
              taskOptions: x.taskOptions ?? [],
              settingFinishOnFulfilled: x.settingFinishOnFulfilled ?? null,
              settingItemOutputWarehouseStatus: withFallbackValuesMaybe(x.settingItemOutputWarehouseStatus, {
                id: ``,
                name: ``,
              }),
              settingItemConsumptionWarehouseStatus: withFallbackValuesMaybe(x.settingItemConsumptionWarehouseStatus, {
                id: ``,
                name: ``,
              }),
              settingPackInputNextWarehouseStatus: withFallbackValuesMaybe(x.settingPackInputNextWarehouseStatus, {
                id: ``,
                name: ``,
              }),
              settingPackOutputNextWarehouseStatus: withFallbackValuesMaybe(x.settingPackOutputNextWarehouseStatus, {
                id: ``,
                name: ``,
              }),
              settingLoadInputNextWarehouseStatus: withFallbackValuesMaybe(x.settingLoadInputNextWarehouseStatus, {
                id: ``,
                name: ``,
              }),
              settingLoadOutputNextCarrierStatus: x.settingLoadOutputNextCarrierStatus ?? null,
              settingsResourceReport: x.settingsResourceReport ?? null,
            })),
            activity: template.workItemTemplate.activity,
            autoClose: template.workItemTemplate.autoClose ?? false,
            defaultPriority: template.workItemTemplate.defaultPriority ?? WorkPriority.None,
            defaultOwner: template.workItemTemplate.defaultOwner,
            defaultSkills: (template.workItemTemplate.defaultSkills ?? [])
              .filter(defined)
              .map(FallbackValues({ id: ``, name: ``, code: null })),
          }
        : {
            id: null,
            type: toWorkItemType(WorkItemType.AdHoc),
            companyId: +companyId,
            name: ``,
            adhoc: false,
            activity: null,
            tasks: [],
            autoClose: false,
            settingTrackingIdStatusId: null,
            defaultPriority: WorkPriority.None,
            defaultOwner: null,
            defaultSkills: [],
          },
    [template, companyId],
  )

  // const [upsert, upsertMutation] = useUpsertTemplateMutation()

  const handleSave = async (form: WorkItemTemplateForm) => {
    const patchedForm = TemplateDialog.patchExcessResultTypes(form)
    const activityAlreadyAssignedToTemplate = templates.workItemTemplates?.page
      ?.filter((t: any) => t.id !== patchedForm.id)
      .some((t: any) => t.activity?.id === patchedForm.activity?.id)
    if (activityAlreadyAssignedToTemplate) {
      handleAlert('warning')
      return
    }

    const input = {
      autoClose: patchedForm.autoClose ?? false,
      type: patchedForm.type?.id as WorkItemType,
      defaultPriority: patchedForm.defaultPriority,
      defaultOwnerId: patchedForm.defaultOwner ? +patchedForm.defaultOwner.id : null,
      defaultSkillIds: patchedForm.defaultSkills.map(x => +x.id),
      companyId: +patchedForm.companyId,
      // @todo ask question about a warning, had to add '?? ``' to remove warning, but most likely this is not correct.
      name: patchedForm.name ?? ``,
      activityId: +patchedForm.activity!.id,
      // @todo ask question about a warning, had to add '?' and '?? []' to remove warning, but most likely this is not correct.
      tasks: (patchedForm.tasks ?? []).map(t => ({
        name: t.name,
        order: t.order,
        types: t.types,
        taskOptions: t.taskOptions,
        settingFinishOnFulfilled: t.settingFinishOnFulfilled ?? null,
        settingItemOutputWarehouseStatusId: t.settingItemOutputWarehouseStatus
          ? +t.settingItemOutputWarehouseStatus.id
          : null,
        settingItemConsumptionWarehouseStatusId: t.settingItemConsumptionWarehouseStatus
          ? +t.settingItemConsumptionWarehouseStatus.id
          : null,
        settingPackInputNextWarehouseStatusId: t.settingPackInputNextWarehouseStatus
          ? +t.settingPackInputNextWarehouseStatus.id
          : null,
        settingPackOutputNextWarehouseStatusId: t.settingPackOutputNextWarehouseStatus
          ? +t.settingPackOutputNextWarehouseStatus.id
          : null,
        settingLoadInputNextWarehouseStatusId: t.settingLoadInputNextWarehouseStatus
          ? +t.settingLoadInputNextWarehouseStatus.id
          : null,
        settingLoadOutputNextCarrierStatus: t.settingLoadOutputNextCarrierStatus,
        settingsResourceReport: t.settingsResourceReport ?? null,
      })),
      appName: AppName.WorkApp,
    }

    if (patchedForm.id) {
      updateTemplate({
        variables: {
          id: +patchedForm.id,
          input: input,
        },
      })
    } else {
      createTemplate({
        variables: {
          input: input,
        },
      })
    }

    // const saved = await upsert({
    //   refetchQueries: [namedOperations.Query.workItemTemplatesByCompany],
    //   variables: {
    //     id: patchedForm.id ?? undefined,
    //     values: {
    //       // @todo ask question about a warning, had to add '?? false' to remove warning, but most likely this is not correct.
    //       autoClose: patchedForm.autoClose ?? false,
    //       type: patchedForm.type?.id as WorkItemType,
    //       defaultPriority: patchedForm.defaultPriority,
    //       defaultOwnerId: patchedForm.defaultOwner?.id ?? null,
    //       defaultSkillIds: patchedForm.defaultSkills.map((x) => x.id),
    //       companyId: patchedForm.companyId,
    //       // @todo ask question about a warning, had to add '?? ``' to remove warning, but most likely this is not correct.
    //       name: patchedForm.name ?? ``,
    //       activityId: patchedForm.activity?.id,
    //       // @todo ask question about a warning, had to add '?' and '?? []' to remove warning, but most likely this is not correct.
    //       tasks: (patchedForm.tasks ?? []).map((t) => ({
    //         name: t.name,
    //         order: t.order,
    //         types: t.types,
    //         taskOptions: t.taskOptions,
    //         settingFinishOnFulfilled: t.settingFinishOnFulfilled ?? null,
    //         settingItemOutputWarehouseStatusId: t.settingItemOutputWarehouseStatus?.id,
    //         settingItemConsumptionWarehouseStatusId: t.settingItemConsumptionWarehouseStatus?.id,
    //         settingPackInputNextWarehouseStatusId: t.settingPackInputNextWarehouseStatus?.id,
    //         settingPackOutputNextWarehouseStatusId: t.settingPackOutputNextWarehouseStatus?.id,
    //         settingLoadInputNextWarehouseStatusId: t.settingLoadInputNextWarehouseStatus?.id,
    //         settingLoadOutputNextCarrierStatus: t.settingLoadOutputNextCarrierStatus,
    //         settingsResourceReport: t.settingsResourceReport ?? null,
    //       })),
    //     },
    //   },
    // })

    // if (saved.data?.workItemTemplates.upsert) {
    //   if (templateId) {
    //     handleAlert('edit')
    //     h.push(`/work-item-templates`)
    //   } else {
    //     handleAlert('create')
    //     h.push(`/work-item-templates`)
    //   }
    // }
  }

  const handleCancel = () => {
    h.push(`/work-item-templates`)
  }

  const isTemplateAdmin = useRoleAvailable(`ADMIN_TEMPLATE_ADMIN`)

  const [deleteTemplate, setDeleteTemplate] = useState(``)
  const handleDeleteTemplate = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isTemplateAdmin) {
      setDeleteTemplate(templateId!)
    }
  }
  const deleteTemplateClose = () => setDeleteTemplate('')

  const schema = TemplateDialog.useSchema()

  const alreadyAssignedActivityIds =
    templates?.workItemTemplates?.page
      ?.filter((t: any) => t.id !== template?.id && t.activity?.id)
      .map((t: any) => t.activity.id) ?? []

  return (
    <>
      {deleteTemplate && (
        <DeleteTemplateDialog
          open={!!deleteTemplate}
          templateId={deleteTemplate}
          onClose={deleteTemplateClose}
          handleAlert={handleAlert}
          editPopupClose={handleCancel}
        />
      )}
      {alert !== `warning` && alert !== `duplicate` && (
        <AlertDialog alert={alert} entity={`Template`} handleClose={handleAlertClose} />
      )}
      <PatternDialog<WorkItemTemplateForm>
        title={templateId ? t(`Edit Template`) : t(`Add Template`)}
        submit={templateId ? t(`Save`) : t(`Create`)}
        initialValues={initialValues}
        onClose={handleCancel}
        onSave={handleSave}
        onDelete={handleDeleteTemplate}
        schema={schema}
        open={Boolean(action || templateId)}
        // query={[templateId ? templateQuery : null, activitiesQuery].filter(defined)}
        // mutation={[upsertMutation]}
        query={null}
        mutation={null}
        alert={alert}
        onAlertClose={handleAlertClose}
      >
        <TemplateDialogForm alreadyAssignedActivityIds={alreadyAssignedActivityIds} />
      </PatternDialog>
    </>
  )
}

const TemplateDialogForm = ({ alreadyAssignedActivityIds }: { alreadyAssignedActivityIds: string[] }) => {
  const { t } = useTranslation()
  const f = useFormikContext<WorkItemTemplateForm>()
  const templateTypeChoices = Object.values(WorkItemType).map(key => ({ id: key, name: t(`WorkItemType.${key}`) }))
  const excessResultTypes = TemplateDialog.getExcessResultTypes(f.values)

  return (
    <MSections>
      <MSection
        title={t(
          `General Information`,
        )} /*help={<HelpIcon description="Short description TODO" url="https://google.com" />}*/
      >
        <MBlock padding={4}>
          <Field component={MFieldConnector} name='name' fullWidth label={t(`Name`)}>
            <MFieldInput margin='dense' autoFocus inputProps={{ maxLength: 25 }} />
          </Field>
          <Field component={AutocompleteField} name='type' label={t(`Work Item Type`)} options={templateTypeChoices} />
          <Field component={MFieldConnector} name='activity' label={t(`Activity`)}>
            <FieldActivity alreadyAssignedActivityIds={alreadyAssignedActivityIds} />
          </Field>
          <Field component={MFieldConnector} name='defaultPriority' label={t(`Priority`)}>
            <FieldPriority />
          </Field>
          <Field
            component={CheckboxField}
            name='autoClose'
            fullWidth
            label={t(`Auto Close`)}
            description={t(`When all tasks are done, close the work item automatically`)}
          />
          <Field component={MFieldConnector} name='defaultOwner' label={t(`Assign Team Lead`)}>
            <FieldResource title={t(`Assign Team Lead`)} />
          </Field>
          <Field component={MFieldConnector} name='defaultSkills' label={t(`Required Skills`)}>
            <FieldSkillSet title={t(`Required Skills`)} />
          </Field>
        </MBlock>
      </MSection>
      <MSection
        title={t(`Tasks`)} /*help={<HelpIcon description="Short description TODO" url="https://google.com" />}*/
      >
        <Field component={MFieldConnector} name='tasks'>
          <TaskListField templateType={(f.values.type?.id as WorkItemType) ?? WorkItemType.AdHoc} />
        </Field>
      </MSection>
      <Collapse in={excessResultTypes.length > 0}>
        <MSection title={t(`Validation`)}>
          <MFlexBlock padding={4} gap={2}>
            <MFlexBlock shrink={0}>
              <IconWarning color='error' fontSize='large' />
            </MFlexBlock>
            <MFlexBlock grow={0}>
              <MText block textColor={MTextColor.dark}>
                The following task result types will be removed:
                <ul>
                  {excessResultTypes.map(x => (
                    <li>{t(`WorkResultType.${x}`)}</li>
                  ))}
                </ul>
                They are not compatible with the current work item type.
              </MText>
            </MFlexBlock>
          </MFlexBlock>
        </MSection>
      </Collapse>
    </MSections>
  )
}

TemplateDialog.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<WorkItemTemplateForm>({
      companyId: yup.string().label(`companyId`),
      id: yup.string().optional().nullable(),
      name: yup.string().label(t(`Name`)).required(),
      type: yupObject({ id: yup.string(), name: yup.string() }).label(t(`Type`)).required().nullable() as any,
      autoClose: yup.boolean().label(t(`Auto Close`)).required(),
      activity: yup.object().label(t(`Activity`)).required().nullable() as any,
      tasks: yup.array().min(1, t(`TasksField`)).label(t(`Tasks`)).required() as any,
      defaultPriority: yupEnum(WorkPriority).label(t(`Priority`)).required() as any,
      defaultOwner: yupObject({ id: yup.string(), name: yup.string() })
        .label(t(`Assign Team Lead`))
        .optional()
        .nullable() as any,
      defaultSkills: yupObjectArray({ id: yup.string(), name: yup.string() }) as any,
    }),
  )

  return schema
}

TemplateDialog.getExcessResultTypes = (form: WorkItemTemplateForm) => {
  const templateType = (form.type?.id as WorkItemType) ?? WorkItemType.AdHoc
  const resultTypes = Array.from(new Set(form.tasks?.flatMap(x => x.types)))

  const validResultTypes = AvailableResultTypes[templateType] ?? []
  const excessResultTypes = resultTypes.filter(x => !validResultTypes.includes(x))

  return excessResultTypes
}

TemplateDialog.patchExcessResultTypes = (form: WorkItemTemplateForm) => {
  const excessResultTypes = TemplateDialog.getExcessResultTypes(form)

  const tasks = form.tasks?.map(
    (x): WorkItemTemplateTaskForm => ({
      ...x,
      types: x.types.filter(x => !excessResultTypes.includes(x)),
    }),
  )

  return { ...form, tasks }
}
