import { Autocomplete, TextField } from '@mui/material'
import { useMField } from '@mprise/react-ui'
import { useUniqueId } from '@mprise/react-ui/dist/internals'
import { useEffect, useState } from 'react'
import { GET_ACTIVITIES } from '../../gql/activities'
import { useLazyQuery } from '@apollo/client'
import { useCurrentCompanyId } from '../useCurrentCompany'

type FieldActivityValue = {
  id: string
  code: string
  name: string
}

export const FieldActivity = ({ alreadyAssignedActivityIds }: { alreadyAssignedActivityIds: string[] }) => {
  const f = useMField()
  const uniqueId = useUniqueId(f.id ?? ``)
  const [search, setSearch] = useState(``)
  const companyId = useCurrentCompanyId()

  const [getActivities, { error: activitiesError, data: activities }] = useLazyQuery(GET_ACTIVITIES)

  if (activitiesError) {
    console.error(activitiesError)
  }

  useEffect(() => {
    getActivities({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
        },
        limit: 10000, // For full list
        offset: 0,
      },
    })
  }, [])

  const [open, setOpen] = useState(false)

  return (
    <Autocomplete
      getOptionLabel={option => String(option.code ?? option.name ?? option.id ?? '-')}
      id={f.id ?? uniqueId}
      inputValue={search}
      options={
        activities?.activities?.page.filter((a: FieldActivityValue) => !alreadyAssignedActivityIds.includes(a.id)) ?? []
      }
      isOptionEqualToValue={(x: any, y: any) => x.id === y.id || x === y}
      renderInput={params => <TextField variant='standard' {...params} />}
      value={f.value}
      onChange={(_, v) => f.onChange?.(v)}
      onInputChange={(_, x) => setSearch(x)}
      open={open}
      onOpen={() => {
        setSearch('')
        setOpen(true)
      }}
      onClose={() => setOpen(false)}
      onBeforeInput={() => setOpen(true)}
    />
  )
}
