import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MColor,
  MFieldInput,
  MFieldInteger,
  MFlexBlock,
  MFlexItem,
  MRadioGroup,
  MText,
  MTextColor,
} from '@mprise/react-ui'
import { Field, Formik, useFormikContext } from 'formik'
import { AlertType } from '../../shared/alert-dialog'
import { FieldResource } from '../../shared/form/field-resource'
import { FieldResourceSet, ResourceValue } from '../../shared/form/field-resource-set'
import { FieldSkillSet, SkillValue } from '../../shared/form/field-skill-set'
import { withFormikCompareFix } from '../../shared/formik'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { FormikDialog } from '../../shared/dialog/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { Maybe, defined } from '../../shared/typescript'
import { ValidationIssues } from '../../shared/validation-issues'
import { yup, yupEnum, yupObject, yupObjectArray } from '../../shared/yup-common-types'
import { TeamResourceMode } from '../../lib/enums'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_TEAM, GET_TEAMS, UPDATE_TEAM } from '../../gql/teams'
import { Collapse } from '@mui/material'

const MAX_LENGTH_INPUT_FIELD = 50

export interface UpdateTeamInput {
  teamId: number
  name: string
  leaderResource: Maybe<ResourceValue>
  resourceMode: TeamResourceMode
  resources: Array<ResourceValue>
  resourceCount: number
  skills: Array<SkillValue>
}

export const UpdateTeamDialog = ({
  handleAlert,
  open,
  teamId,
  onClose,
  onDelete,
  onFinished,
}: {
  handleAlert: (alertType: AlertType) => void
  open: boolean
  teamId: string
  onClose: () => void
  onDelete: any
  onFinished: (args: { teamId: string }) => void
}) => {
  const { t } = useTranslation()

  const [getTeam, { error: teamError, loading: teamLoading, data: team }] = useLazyQuery(GET_TEAM, {
    variables: { filter: { id: +teamId } },
  })

  const [conflict, setConflict] = useState(false)
  const [conflicts, setConflicts] = useState<string[]>([])

  if (teamError) {
    console.error('teamError', teamError)
  }

  const [updateTeam, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_TEAM, {
    refetchQueries: [GET_TEAMS],
  })

  if (updateError) {
    console.error('updateError', updateError)
  }

  useEffect(() => {
    if (teamId) {
      getTeam({
        variables: {
          filter: {
            id: +teamId,
          },
        },
      })
      setConflict(false)
    }
  }, [])

  const [initialValues] = useLocalState<UpdateTeamInput>(() => {
    if (team) {
      return withFormikCompareFix({
        teamId: +team.team.id,
        resourceMode: team.team.resourceMode ?? TeamResourceMode.List,
        name: team.team.name ?? ``,
        leaderResource: team.team.leader ? { ...team.team.leader, type: `resource` } : null,
        resources: (team.team.resources ?? []).filter(defined).map((x: any) => ({ ...x, type: `resource` })),
        resourceCount: team.team.resourceCount ?? 0,
        skills: (
          team.team.skills.filter(defined).map((x: any) => {
            return {
              id: x.id?.toString(),
              ...x,
            }
          }) ?? []
        ).filter(defined),
      })
    } else {
      return withFormikCompareFix({
        teamId: -1,
        resourceMode: TeamResourceMode.List,
        name: ``,
        leaderResource: null,
        resourceCount: 0,
        resources: [],
        skills: [],
      })
    }
  }, [team, open])
  // const [initialValues] = useLocalState<UpdateTeamInput>(
  //   () =>
  //     withFormikCompareFix({
  //       teamId: -1,
  //       resourceMode: TeamResourceMode.List,
  //       name: ``,
  //       leaderResource: null,
  //       resourceCount: 0,
  //       resources: [],
  //       skills: []
  //     }),
  //   [team, open]
  // )

  // const { save, mutations } = UpdateTeamDialog.useSave()

  useEffect(() => {
    if (updateData) {
      onFinished({ teamId: teamId })
      handleAlert('edit')
    }
  }, [updateData])

  const handleSubmit = async (form: UpdateTeamInput) => {
    const conflicts = []
    if (!form.leaderResource?.id) {
      conflicts.push(t('Team Leader is a Required Field'))
    }

    if (conflicts.length > 0) {
      setConflict(true)
      setConflicts(conflicts)
    } else {
      updateTeam({
        variables: {
          id: +teamId,
          input: {
            name: form.name,
            leader: +form.leaderResource?.id!,
            resourceMode: form.resourceMode,
            resources: form.resources.map(r => +r.id!),
            resourceCount: form.resourceCount,
            skills: form.skills.map(s => +s.id),
          },
        },
      })
    }
  }

  const schema = UpdateTeamDialog.useSchema()

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <FormikDialog
        minWidth='sm'
        open={open}
        title={t('DialogEditTeamTitle')}
        submit={t('Save')}
        removeChildren={t('ACTION_REMOVE')}
        onClose={onClose}
        onRemove={onDelete}
      >
        <SavingSwitchPanel savingAnyway={updateLoading} mutation={null}>
          <LoadingSwitchPanel loadingAnyway={teamLoading} query={null}>
            {/* <MutationErrorMessage mutation={mutations} /> */}
            <ValidationIssues />
            {conflict && <UpdateTeamDialogConflict conflicts={conflicts} />}
            <UpdateTeamDialogForm />
          </LoadingSwitchPanel>
        </SavingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}

const UpdateTeamDialogConflict = ({ conflicts }: { conflicts: string[] }) => {
  return (
    <Collapse in={true} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          {conflicts.map((c: any) => (
            <MText block textVariant='small'>
              {c}
            </MText>
          ))}
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

const UpdateTeamDialogForm = () => {
  const { t } = useTranslation()
  const f = useFormikContext<UpdateTeamInput>()
  const isResourceModeList = f.values.resourceMode === TeamResourceMode.List
  const isResourceModeCount = f.values.resourceMode === TeamResourceMode.Count

  return (
    <MSections>
      <MSection title={t(`Team Information`)}>
        <Field component={MFieldConnector} name='resourceMode' label={t(`Type`)}>
          <MRadioGroup>
            <MRadioGroup.Button
              name='resourceMode'
              value={TeamResourceMode.List}
              checked={isResourceModeList}
              onChange={({ checked, value }) => checked && f.setFieldValue(`resourceMode`, value)}
              disabled={isResourceModeCount}
            >
              <MText block textColor={isResourceModeCount ? MTextColor.disabled : ``}>
                {t(`Named resources`)}
              </MText>
            </MRadioGroup.Button>
            <MRadioGroup.Button
              name='resourceMode'
              value={TeamResourceMode.Count}
              checked={isResourceModeCount}
              onChange={({ checked, value }) => checked && f.setFieldValue(`resourceMode`, value)}
              disabled={isResourceModeList}
            >
              <MText block textColor={isResourceModeList ? MTextColor.disabled : ``}>
                {t(`No. of resources`)}
              </MText>
            </MRadioGroup.Button>
          </MRadioGroup>
        </Field>
        <Field component={MFieldConnector} name='name' label={t(`Name`)}>
          <MFieldInput autoFocus inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
        <Field component={MFieldConnector} name='leaderResource' label={t(`Team Lead`)}>
          <FieldResource title={t(`Select Team Lead`)} />
        </Field>
        {f.values.resourceMode === TeamResourceMode.List ? (
          <Field component={MFieldConnector} name='resources' label={t(`Resources`)}>
            <FieldResourceSet title={t(`Select Resources`)} />
          </Field>
        ) : null}
        {f.values.resourceMode === TeamResourceMode.Count ? (
          <Field component={MFieldConnector} name='resourceCount' label={t(`Total number of resources`)}>
            <MFieldInteger min={0} />
          </Field>
        ) : null}
      </MSection>
      <MSection title={t(`Skills`)}>
        <Field component={MFieldConnector} name='skills' label={t(`Skills`)}>
          <FieldSkillSet title={t(`Select Skills`)} />
        </Field>
      </MSection>
    </MSections>
  )
}

UpdateTeamDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(() => {
    return yupObject<UpdateTeamInput>({
      teamId: yup.string().label(t(`Team Id`)).required(),
      resourceMode: yupEnum(TeamResourceMode).required(),
      name: yup.string().label(t(`Name`)).required().max(MAX_LENGTH_INPUT_FIELD),
      leaderResource: yupObject({ id: yup.string().required(), name: yup.string().required() })
        .label(t(`Team Lead`))
        .nullable()
        .required() as any,
      resources: yupObjectArray({ id: yup.string().required(), name: yup.string().required() }).label(
        t(`Resources`),
      ) as any,
      resourceCount: yup.number().min(0).label(t(`Total number of resources`)).required(),
      skills: yupObjectArray({ id: yup.string().required(), name: yup.string().required() }).label(t(`Skills`)) as any,
    })
  })
  return schema
}

// UpdateTeamDialog.useSave = () => {
//   const [updateTeamDetails, updateTeamDetailsMutation] = useUpdateTeamDetailsMutation()
//   const [updateTeamResources, updateTeamResourcesMutation] = useUpdateTeamResourcesMutation()
//   const [updateTeamResourceCount, updateTeamResourceCountMutation] = useUpdateTeamResourceCountMutation()

//   async function save(form: UpdateTeamInput) {
//     const updated = await updateTeamDetails({
//       refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//       variables: {
//         teamId: form.teamId.toString(),
//         name: form.name,
//         leaderResourceId: form.leaderResource?.id!.toString(),
//         skillIds: form.skills.map((x) => x.id.toString()),
//       },
//     })
//     if (!updated.data?.details?.setName?.id) {
//       return null
//     }

//     if (form.resourceMode === TeamResourceMode.Count) {
//       const updated = await updateTeamResourceCount({
//         refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//         variables: {
//           teamId: form.teamId.toString(),
//           resourceIdCount: form.resourceCount,
//         },
//       })
//       if (!updated.data?.team?.setResourcesByCount?.id) {
//         return null
//       }
//     } else {
//       const updated = await updateTeamResources({
//         refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//         variables: {
//           teamId: form.teamId.toString(),
//           resourceIds: form.resources.map((x) => x.id?.toString()).filter(defined),
//         },
//       })
//       if (!updated.data?.team?.setResourcesByList?.id) {
//         return null
//       }
//     }
//     return updated.data
//   }

//   return { save, mutations: [updateTeamDetailsMutation, updateTeamResourcesMutation, updateTeamResourceCountMutation] }
// }
