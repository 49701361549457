import { useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { GET_RESOURCES } from '../../gql/resource'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { FieldSelect } from './field-select'
import { Maybe } from 'yup'

export type ResourceValue = {
  id: number
  code: Maybe<string>
  name: Maybe<string>
  type: Maybe<string>
}

export const FieldResourceSet = ({ title }: { title: string }) => {
  const f = useMField()
  const selected: ResourceValue[] = f.value
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  return (
    <>
      {open && (
        <MultiSelectDialog<ResourceValue>
          displayProperty='name'
          open={open}
          onClose={handleClose}
          onSave={resource => {
            f.onChange?.(resource)
            setOpen(false)
          }}
          title={title}
          initialValue={selected}
          getRecordsQuery={GET_RESOURCES}
          queryResultAccessor={data => data?.resources?.page}
        />
      )}
      <FieldSelect<ResourceValue>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
